.loads-content {
    height: 100vh;
}

.loads-content .ant-tabs-content {
    height: 100%;
}

.laod-table {
    padding-left: 2rem;
    padding-right: 2rem;
}

.ant-modal .ant-table {
    background-color: #fff;

}