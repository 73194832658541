.cc-layout .ant-spin-dot-item {
  background-color: white;
}

.center-flex {
  display: flex;
  justify-content: center;
}

.loading-content {
  height: 100vh;
}

.cc-layout .loading-content .ant-spin-dot-item {
  background-color: var(--primary-color, blue);
}

.cc-layout {
  height: 100vh;
}

.ant-layout-sider-trigger {
  background-color: #2e382f !important;
}

.table-row-highlight {
  background-color: #fff3cd;
}

.ant-card-head {
  border-bottom: 1px solid #4daa48 !important;
}

.ant-card-head-title {
  text-align: center;
  font-size: 1rem;
  font-weight: bolder;
}

.ant-table-content,
.user-card,
.users-card,
.company-card {
  box-shadow: 0px 0px 5px 1px #4daa48;
  border-radius: 0.5rem;
}

div.ant-table-summary.ant-table-sticky-holder {
  bottom: -8px !important;
}

div.ant-table-header.ant-table-sticky-holder {
  top: -34px !important;
}
